import React from 'react'
import PropTypes from 'prop-types'
import FeaturesBlocks from "../components/feature-blocks";
import HeroHome from "../components/hero-home";
import Partners from '../components/partners'

function Home(props) {
    return (
        <main className="flex-grow">
            <HeroHome />
            <FeaturesBlocks />
            <Partners />
            {/* <Footer /> */}
        </main>
    )
}

Home.propTypes = {

}

export default Home

