import React from 'react'

function NavBtn(props) {
    const {icon, name, routeName} = props;
    return (
        <li className="nav-item">
        <a
          className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-gray-900 hover:opacity-75"
          href={routeName}
        >
          <i className={`${icon} text-lg leading-lg opacity-75`}></i><span className="ml-2">{name}</span>
        </a>
      </li>
    )
}

export default NavBtn
