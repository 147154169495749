import React from 'react'

function AboutUs(props) {
    return (
        <main className="flex-grow">
            <section className="bg-gradient-to-b from-gray-100 to-white">
                <div class="flex flex-col h-screen bg-gray-100">
                    About Us
                </div>
            </section>
        </main>
    )
}

AboutUs.propTypes = {

}

export default AboutUs