import React from 'react'

function Donate(props) {
    return (
        <main className="flex-grow">
            <section className="bg-gradient-to-b from-gray-100 to-white">
           		Donate page will be constructed here 
			</section>
        </main>
    )
}

Donate.propTypes = {

}

export default Donate
