
import Navbar from './components/nav';
import Home from './pages/Home';
import {
  Switch,
  Route,
  useLocation
} from 'react-router-dom';
import { useEffect } from 'react';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import Donate from './pages/Donate.js';
import AboutUs from './pages/About.js';



function App() {
  const location = useLocation();

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
  }, [location.pathname]); 


  return (
    <>
      <header className="relative z-10 max-w-screen-lg xl:max-w-screen-xl mx-auto">
        <Navbar />
      </header>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/signin">
          <SignIn />
        </Route>
        <Route path="/signup">
          <SignUp />
        </Route>
        <Route path="/donate">
          <Donate />
        </Route>
		<Route path="/about">
          <AboutUs />
        </Route>


      </Switch>
    </>

  );
}

export default App;
