import React, { 
    // useState
 } from 'react';
import ecoImage from '../images/ecoimage.svg'

function HeroHome() {

    // const [videoModalOpen, setVideoModalOpen] = useState(false);

    return (
        <section className="relative">
      <div className="absolute left-0 right-0 bottom-0 m-auto w-px p-px h-20 bg-gray-200 transform translate-y-1/2"></div>

            <div className="max-w-6xl mx-auto px-4 sm:px-6">

                {/* Hero content */}
                <div className="pt-20 pb-10 md:pt-30 md:pb-10">

                    {/* Section header */}
                    <div className="text-center pb-12 md:pb-16">
                        <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">Text to be accompanied for<br/><span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-green-400">  Safer Earth</span></h1>
                        
                        <div className="max-w-3xl mx-auto">
                            <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">Our landing page template works on all devices, so you only have to set it up once, and get beautiful results forever.</p>
                            <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center" data-aos="zoom-y-out" data-aos-delay="300">
                                <div>
                                    <a className="btn text-white bg-blue-600 hover:bg-blue-700 w-full mb-4 sm:w-auto sm:mb-0" href="signup">Donate</a>
                                </div>
                                <div>
                                    <a className="btn text-white bg-gray-900 hover:bg-gray-800 w-full sm:w-auto sm:ml-4" href="signup">Sign Up</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Hero image */}
                    <div>
                        <div className="relative flex justify-center mb-8" data-aos="zoom-y-out" data-aos-delay="450">
                            <div className="flex flex-col justify-center">
                                <img className="mx-auto" src={ecoImage} width="368" height="332" alt="Hero" />
                            </div>
                            
                        </div>

                        {/* Modal */}
                        {/* <Modal id="modal" ariaLabel="modal-headline" show={videoModalOpen} handleClose={() => setVideoModalOpen(false)}>
              <div className="relative pb-9/16">
                <iframe className="absolute w-full h-full" src="https://player.vimeo.com/video/174002812" title="Video" allowFullScreen></iframe>
              </div>
            </Modal> */}

                    </div>

                </div>

            </div>

        </section>
    );
}

export default HeroHome;